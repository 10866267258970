import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { adminMakePremium } from '../../../API/userManagementApi';
import { toast } from 'react-toastify';
import DeleteUserPrem from '../review_management/deleteUserPrem';

const UserPremium = ({ userData,handleCloseModal,fetchUserData }) => {
    console.log({fetchUserData})
    const [selectedDate, setSelectedDate] = useState(dayjs(userData?.premium?.endDate)||dayjs());
    const [data, setData] = useState(dayjs());
const[openDelete,setOpenDelete]=useState()
const [isOpenModal,setIsOpenModal]=useState()

// useEffect(()=>{
//     if (userData?.premium?.endDate) {
//         setSelectedDate(dayjs(userData.premium.endDate));
//     }},[userData])
    const handleChange = (newDate) => {
        setSelectedDate(newDate);
    };
    const handleSubmit = () => {
        const fetchUser = () => {
            

            const payload = {
                "userId": userData?._id,
                "endDate": selectedDate
            }
            adminMakePremium(payload)
                .then((response) => {setData(response?.data?.data)
                     toast.success("success", {
                                        autoClose: 5000,
                                        toastId: "adminMakePrem"
                                    });

                                    handleCloseModal()
                                    fetchUserData()
                                })
                .catch((error) => toast.error(error?.message, {
                    autoClose: 5000, toastId: "fgdg"
                }));
        };

        fetchUser();

    }

    const handleCancel=()=>{
        setOpenDelete(true)
    }
    return (
        <div className=" h-fit flex flex-col font-semibold justify-between">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={selectedDate}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                    slotProps={{
                        day: {
                            sx: (params) => ({
                                ...(params.today && {
                                    color: '#fff', // White text color for today
                                    backgroundColor: '#800080 !important', // Purple background for today
                                    fontWeight: 'bold',
                                    borderRadius: '50%',
                                    '&:hover': {
                                        backgroundColor: '#9b59b6', // Lighter purple on hover
                                    },
                                }),
                                ...(params.selected && {
                                    color: '#fff', // White text color for selected date
                                    backgroundColor: '#800080', // Keep the selected date in purple
                                    fontWeight: 'bold',
                                    borderRadius: '50%',
                                    '&:hover': {
                                        backgroundColor: '#9b59b6', // Lighter purple on hover
                                    },
                                }),
                            }),
                        },
                    }}
                />
            </LocalizationProvider>

            <div  className="content2 mt-[10px]  text-[#524CBB] font-[600] text-[14px] display-[flex] gap-[10px] justify-[center]" style={{ fontFamily: "Plus Jakarta Sans" ,display:"flex",justifyContent:"center",alignItems:"center"}}>
                <button
                onClick={handleCancel}
                    className="text-[#888888] !bg-[#EEEEEE] text-[14px] font-medium rounded-[6px] px-[16px] py-[10px]"
                    type="button"
                >
                    Cancel
                </button>

                <button type="submit" className="prem-btn " onClick={handleSubmit}>
                    <span
                        className="font-bold text-white"
                        style={{
                            fontFamily: "Plus Jakarta Sans, sans-serif",
                            fontWeight: "700",
                            fontSize: "16px",
                            padding: "0",
                        }}
                    >
                        Submit
                    </span>
                </button>
            </div>

            {openDelete &&
            <DeleteUserPrem  setIsOpenModal={setOpenDelete} isOpenModal={openDelete} data={userData} handleCloseModal={handleCloseModal}/>
            }
        </div>
    );
};

UserPremium.propTypes = {
    userData: PropTypes.object,
};

export default UserPremium;
