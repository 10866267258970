import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { Tab, Tabs, styled } from "@mui/material";
import GratiTipRevenue from "./grati_tip_revenue";
import SubscriptionRevenue from "./subscription_revenue";
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    fontSize: "16px",
    marginRight: "20px",
    color: "#888888",
    "&.Mui-selected": {
      color: "#524CBB",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#524CBB",
    },
  })
);

export default function RevenueReports() {
  const [selectedTab, setSelectedTab] = useState("gratiTip");
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="flex flex-col h-[100%] max-h-[87vh] justify-start gap-6 ">
      <Paper
        className="flex flex-col justify-between gap-6 p-6 pb-0"
        style={{ borderRadius: "12px", justifyContent: "flex-start" }}
        component={Paper}>
        <div
          className="flex justify-between"
          style={{ alignItems: "baseline" }}>
          <p
            className="text-[18px] font-[700] text-black"
            style={{ fontFamily: "Plus Jakarta Sans" }}>
            Revenue Reports
          </p>
        </div>

        <div className="flex justify-between mt-auto">
          <StyledTabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="manage collaboration tabs">
            <StyledTab value="gratiTip" label="Grati tip based revenue" />
            <StyledTab
              value="subscription"
              label="Subscription based revenue"
            />
          </StyledTabs>
        </div>
      </Paper>
      {selectedTab === "gratiTip" ? (
        <GratiTipRevenue />
      ) : (
        <SubscriptionRevenue />
      )}
    </div>
  );
}
