// auth constants
const API_URL = process.env.REACT_APP_ADMIN_PANEL_URL;
const API = {
  AUTH_TOKEN: "Grati",
  AUTHORIZATION_HEADER: "authorization",


  
  //auth 
  LOGIN_URL: API_URL + "admin/login",
  FORGET_PASSWORD: API_URL + "admin/sendOtp",
  OTP_VERIFICATION: API_URL + "admin/verifyOTP",
  RESET_PASSWORD: API_URL + "admin/resetpassword",

  // manage admin
  BLOCK_ADMIN: API_URL + "admin/blockAdmin",
  CREATE_SUBADMIN: API_URL + "admin/createSubAdmin",
  VIEW_ADMIN_DETS: API_URL + "admin/viewAdminDetails",
  LIST_ADMIN_DETS: API_URL + "admin/listAllAdmins",
  EDIT_ADMIN_DETS: API_URL + "admin/editAdminRoles",
  DEL_ADMIN: API_URL + "admin/deleteAdmins",
  SEARCH_ADMIN: API_URL + "admin/searchAdmins",

  // grati support

  QUERY_STATUS: API_URL + "admin/updateQueryStatus",

  // dashboard
  DB_DATA: API_URL + "admin/dashboardData",
  FETCH_NOTIFICATION: API_URL + "admin/fetchAdminNotification",

  //  user management
  USER_DATA: API_URL + "admin/userManagement",
  DAILY_USER_DATA: API_URL + "admin/dailySignupData",
  SEARCH_USER: API_URL + "admin/searchUser",
  GET_GRATITUDE: API_URL + "admin/fecthUserGratitudes",
  ADMIN_MAKES_PREMIUM:API_URL + "admin/makePremium",
  ADMIN_REMOVE_PREMIUM:API_URL + "admin/removePremium",


  //  review management
  REV_DATA: API_URL + "admin/reviewManagement",
  REVIEW_ACTIONS: API_URL + "admin/reviewManagementActions",

  // finance management
  FINANCE_DATA: API_URL + "admin/financeManagement",
  SEARCH_FINANCE: API_URL + "admin/searchByTransaction",
  SUBSCRIPTION_LIST: API_URL + "admin/premium-plan/subscription-list",

  // profile Api
  PROFILE_DETAILS: API_URL + "admin/myProfile",
  UPDATE_PASSWORD: API_URL + "admin/resetMyPassword",
  EDIT_PROFILE: API_URL + "admin/editAdminProfile",
  DOWNLOAD_CSV: API_URL + "admin/downloadCSV",

  // manage collaboration
  QUERIED_USERS: API_URL + "admin/queriedUsers",
  CREATE_POST_IMAGE: API_URL + "admin/collab/createPostForCollab",
  CREATE_POST_VIDEO: API_URL + "admin/collab/postForCollabWtihVideo",
  DELETE_COLLAB_POST: API_URL + "admin/collab/deleteCollabPost",
  EDIT_COLLAB_POST_IMAGE: API_URL + "admin/collab/editPostForCollab",
  EDIT_COLLAB_POST_VIDEO: API_URL + "admin/collab/editPostWtihVideo",
  COLLAB_POST_DATA: API_URL + "admin/collab/fetchCollabPost",

  // product management 
  ADD_PRODUCT: API_URL + "admin/shop/addProduct",
  EDIT_PRODUCT: API_URL + "admin/shop/editProduct",
  GET_PRODUCT: API_URL + "admin/shop/fetchProduct",
  DELETE_PRODUCT: API_URL + "admin/shop/deleteProduct",
  GET_ORDERS: API_URL + "admin/shop/fetchOrdersForAdmin",
  ORDER_DETAILS: API_URL + "admin/shop/fetchOrdersDetailAdmin",
  ORDER_STATUS_UPDATE: API_URL + "admin/shop/updateOrderStatusByAdmin",
  SEARCH_ORDER: API_URL + "admin/shop/searchOrders",
  DOWNLOAD_USER_QR: API_URL + "admin/shop/downloadUserQR",
  DRAG_DROP: API_URL + "admin/shop/updateProductOrder"
}
export default API;
