import React, { useState, useEffect } from "react";
import Download from "../../../../../Assets/images/icons/download.png";
import { motion } from "framer-motion";
import { financeData, searchByTxn, subscriptionData } from "../../../../API/financeApi";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import {
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getDateRange } from "../../../CustomDatePicker/DateRangeFunction";
import CustomDatePicker from "../../../CustomDatePicker/CustomDatePicker";
import SearchIcon from "../../../../../Assets/images/icons/search.png";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Moment from "react-moment";
import GenericModal from "../../../../GenericModal";
import "../finance.css"

const tableHeading = [
  "S. No.",
  "User Name",
  "Email",
  ""
];
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  zIndex: "1",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "30px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {},
  },
}));
const limitOptions = [10, 25, 50, 75, 100];

const SubscriptionDetails = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchFilled, setIsSearchFilled] = useState(false);
  const [customRange, setCustomRange] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const currentDate = new Date();
  const [clickedRowData, setClickedRowData] = useState([]);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [customDateRange, setCustomDateRange] = useState({
    startDate: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      0,
      0,
      0,
      0
    ).toISOString(),
    endDate: new Date().toISOString(),
  });

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleDateRange = (dateObj) => {
    setCustomDateRange(dateObj);
    setPage(1);
  };
  const handleCloseDatePicker = () => {
    setCustomRange(false);
  };

  const handleSearchClear = (event) => {
    event.target.value ? setIsSearchFilled(true) : setIsSearchFilled(false);
    setSearchQuery("");
    handleSearch("");
  };
  const handleChange = (event) => {
    event.target.value ? setIsSearchFilled(true) : setIsSearchFilled(false);
    setSearchQuery(event?.target?.value);
    handleSearch(event?.target?.value);
  };


  const handlePaginationLimitChange = (event) => {
    setLimit(event?.target?.value);
    setPage(1);
  };

  const handleSearch = async (value) => {
    if (value !== "") {
      let payload = {
        page: 1,
        limit: limit,
        text: value
      }
      setLoading(true);
      subscriptionData(payload)
        .then((response) => {
          setTableData(response?.data?.data?.data);
          setTotalCount(0);
        })
        .catch((error) =>
          toast.error("Error fetching search results:", {
            autoClose: 5000,
            toastId: "Error fetching search results",
          })
        )
        .finally(() => setLoading(false));
    } else if (value === "") {
      fetchSubscriptionDetails();
    }
  };
  const renderTableCell = (content, style = {}) => (
    <TableCell
      style={{
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: "400",
        ...style,
      }}>
      {content}
    </TableCell>
  );

  const renderTextContent = (content, style = {}) => (
    <p
      className="text-[14px] font-[600]"
      style={{
        color: "rgba(136, 136, 136, 0.5)",
        fontFamily: "Plus Jakarta Sans",
        ...style,
      }}>
      {content}
    </p>
  );

  const fetchSubscriptionDetails = () => {
    setLoading(true);
    subscriptionData({ page, limit, searchQuery })
      .then((response) => {
        setSearchQuery("");
        setTableData(response?.data?.data?.data);
        setTotalCount(response?.data?.data?.count);
      })
      .catch((error) => {
        setTableData([]);
        toast.error(error?.messsage, {
          autoClose: 5000,
          toastId: "Error fetching data",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clickedRowHandler = (data) => {
    setDetailModalOpen(true);
    setClickedRowData(data?.premiumhistories || [])
  }

  const subscriptionDetailsModalBody = (
    <div className="detailBody h-[570px] flex flex-col gap-4" >
      {
        clickedRowData?.map((item) => {
          return <div key={item?._id} className=" flex flex-col justify-center gap-2 p-4 border border-grey_border rounded-md	" >
            <div className="flex justify-between items-center" >
              <p className="text-sm text-gray-500">Subscription Id</p>
              <p className="text-sm text-black">{item?.subscriptionId}</p>
            </div>

            <div className="flex justify-between items-center" >
              <p className="text-sm text-gray-500">Payment Details</p>
              <p className="text-sm text-black">{`${item?.paymentMethod.charAt(0)?.toUpperCase()}${item?.paymentMethod?.slice(1)}`}</p>
            </div>

            <div className="flex justify-between items-center" >
              <p className="text-sm text-gray-500">Payment Type</p>
              <p className="text-sm text-black">{`${item?.paymentType.charAt(0)?.toUpperCase()}${item?.paymentType?.slice(1)}`}</p>
            </div>

            <div className="flex justify-between items-center" >
              <p className="text-sm text-gray-500">Start Date</p>
              <p className="text-sm text-black">{`${new Date(item?.startDate).toLocaleDateString()}`}</p>
            </div>

            <div className="flex justify-between items-center" >
              <p className="text-sm text-gray-500">End Date</p>
              <p className="text-sm text-black">{`${new Date(item?.endDate).toLocaleDateString()}`}</p>
            </div>

            <div className="flex justify-between items-center" >
              <p className="text-sm text-gray-500">Duration</p>
              <p className="text-sm text-black">{item?.numberOfDays}</p>
            </div>

            <div className="flex justify-between items-center" >
              <p className="text-sm text-gray-500">Subscription Fee</p>
              <p className="text-sm text-black">{item?.subscriptionFee}</p>
            </div>

            <div className="flex justify-between items-center" >
              <p className="text-sm text-gray-500">Subscription Status</p>
              <p className="text-sm text-black">{item?.subscriptionStatus}</p>
            </div>
          </div>
        })
      }
    </div>
  )

  useEffect(() => {
    fetchSubscriptionDetails();
  }, [limit, page]);

  return (
    <Paper
      className=" h-[100%] max-h-[87vh] pb-6 p-6 flex flex-col gap-6"
      style={{ borderRadius: "12px" }}
      component={Paper}>
      <div
        className="flex justify-between flex-wrap"
        style={{
          alignItems: "center",
          alignContent: "center",
        }}>
        <p
          className="text-[18px] font-[700] text-black"
          style={{ fontFamily: "Plus Jakarta Sans" }}>
          Subscription Details
        </p>

        <div
          className="flex gap-3 h-11 font-[600] text-[14px] "
          style={{ fontFamily: "Plus Jakarta Sans" }}>

          <Search className="flex flex-row justify-between relative !mr-0">
            <SearchIconWrapper>
              <img src={SearchIcon} alt="" className="w-[22px] h-[22px]" />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search By User Name"
              value={searchQuery}
              onChange={handleChange}
              inputProps={{ "aria-label": "search" }}
              className="flex w-[272px] rounded-[6px] gap-1 border-[1px] p-[10px] bg-[#EEEEEE]"
            />
            {isSearchFilled && (
              <button
                className="bg-[#EEEEEE] outline-none px-2 rounded-r-[6px] absolute right-0 top-[12px]"
                onClick={handleSearchClear}>
                X
              </button>
            )}
          </Search>

        </div>
      </div>

      {customRange && (
        <CustomDatePicker
          setDateRange={customDateRange}
          getDateRange={(e) => handleDateRange(e)}
          onClose={handleCloseDatePicker}
          right={"495px"}
          top={"137px"}
        />
      )}
      {loading && (
        <div className="flex items-center justify-center mt-20">
          <CircularProgress style={{ color: "#888888", opacity: "0.5" }} />
        </div>
      )}
      {!loading && (
        <TableContainer className="card overflow-auto">
          {tableData?.length !== 0 ? (
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.8 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="w-[194px] h-11 p-3">
                    {tableHeading.map((heading) => (
                      <TableCell key={heading} className="w-max h-11 p-3">
                        <p
                          className="w-max text-[14px] font-[600] text-[#888888]"
                          style={{ fontFamily: "Plus Jakarta Sans" }}>
                          {heading}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tableData?.map((row, index) => (
                    <TableRow
                      key={row?._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      className="w-[fit] h-13">
                      {renderTableCell(
                        index + 1
                      )}
                      {renderTableCell(
                        row?.userName ? (
                          <p>{row?.userName}</p>
                        ) : (
                          renderTextContent("N/A")
                        )
                      )}
                      {renderTableCell(
                        row?.email ? (
                          <p>{row?.email}</p>
                        ) : (
                          renderTextContent("N/A")
                        )
                      )}
                      {renderTableCell(
                        <button
                          type="button"
                          className={`bg-red-500 rounded-l-[6px] w-[163.5px] h-11 py-[10px] px-4`}
                          style={{
                            backgroundColor: "#524CBB",
                            color: "white"
                          }}
                          onClick={() => clickedRowHandler(row)}
                        >
                          View Details
                        </button>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </motion.div>
          ) : (
            <p className="flex justify-center mt-[150px] text-[18px] font-[600] text-[#888888]">
              No data found
            </p>
          )}
        </TableContainer>
      )}

      <div className="flex justify-between mt-auto">
        <Select
          labelId="pagination-limit-select-label"
          value={limit}
          onChange={handlePaginationLimitChange}
          className="w-[66px] h-11 bg-[#EEEEEE] "
          style={{
            color: "#888888",
            fontSize: "14px",
            fontWeight: "500",
            width: "fit-content",
          }}>
          {limitOptions.map((options) => (
            <MenuItem
              key={options}
              style={{
                "&:hover": {
                  backgroundColor: "#EEEEEE",
                },
              }}
              value={options}>
              {options}
            </MenuItem>
          ))}
        </Select>

        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(totalCount / limit)}
            defaultPage={1}
            siblingCount={3}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </Stack>
      </div>

      {/* Modal having subscription details */}
      <GenericModal open={detailModalOpen} handleClose={() => { setDetailModalOpen(false); setClickedRowData([]) }} isCross title="Subscription Details" content={subscriptionDetailsModalBody} width="30vw" height="60vh" />
    </Paper>
  );
};

export default SubscriptionDetails;
