import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";
import { mockDataset } from "../../manage_collaboration/mock";
import "../../dashboard/dashboard.css";
import Download from "../../../../../Assets/images/icons/download.png";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Moment from "react-moment";

const tableHeading = [
  "S. No.",
  "Tip Amount",
  "Given To / Given By",
  "Grati Fee",
  "Date Range",
  "Total Revenue",
  "Payment methods",
];

export default function GratiTipRevenue() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const totalCount = 1;

  const renderTableCell = (content, style = {}) => (
    <TableCell
      style={{
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: "400",
        ...style,
      }}>
      {content}
    </TableCell>
  );

  const renderTextContent = (content, style = {}) => (
    <p
      className="w-max text-[14px] font-[600]"
      style={{
        fontFamily: "Plus Jakarta Sans",
        color: "rgba(136, 136, 136, 0.5)",
        ...style,
      }}>
      {content}
    </p>
  );

  const handleGratiTipRevenueData = () => {
    setLimit(10);
    setTableData(mockDataset.slice(0, 10));

    setLoading(false);
  };

  useEffect(() => {
    handleGratiTipRevenueData();
  }, []);
  const handlePaginationLimitChange = (event) => {
    setLimit(event?.target?.value);
    setPage(1);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const limitOptions = [10, 25, 50, 75, 100];
  return (
    <Paper
      className="randomClass1 flex flex-col justify-between gap-6 p-6 mb-6"
      style={{ borderRadius: "12px", justifyContent: "flex-start" }}
      component={Paper}>
      <div className="flex justify-between" style={{ alignItems: "baseline" }}>
        <p
          className="text-[18px] font-[700] text-black"
          style={{ fontFamily: "Plus Jakarta Sans" }}>
          Grati tip based revenue
        </p>
        <button
          type="button"
          className="flex flex-row justify-between items-center w-[174px] gap-1 rounded-[6px] py-[10px] px-4 bg-[#524CBB]">
          <p
            className="font-bold text-white"
            style={{
              fontFamily: "Plus Jakarta Sans,sans-serif",
              lineHeight: "24px",
              fontWeight: "500",
              textSize: "16px",
              padding: "0",
            }}>
            Download CSV
          </p>
          <img
            src={Download}
            alt=""
            className="flex justify-center items-center m-auto w-[18.32px] h-[18.32px]  "
          />
        </button>
      </div>
      {loading && (
        <div className="flex items-center justify-center mt-20">
          <CircularProgress style={{ color: "#888888", opacity: "0.5" }} />
        </div>
      )}
      {!loading && (
        <TableContainer className="card w-auto overflow-auto">
          {tableData?.length !== 0 ? (
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.8 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="w-[194px] h-11 p-3">
                    {tableHeading?.map((heading) => (
                      <TableCell key={heading} className="w-max h-11 p-3">
                        <p
                          key={heading}
                          className="w-max text-[14px] font-[600] text-[#888888]"
                          style={{ fontFamily: "Plus Jakarta Sans" }}>
                          {heading}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((row, index) => (
                    <TableRow
                      key={row?._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      className="w-[194px] h-13">
                      {renderTableCell(<p>{index + 1}</p>)}
                      {renderTableCell(row?.amount || renderTextContent("N/A"))}
                      {renderTableCell(
                        row?.userName ||
                          row?.fullName ||
                          renderTextContent("N/A")
                      )}
                      {renderTableCell(row?.amount || renderTextContent("N/A"))}
                      {renderTableCell(
                        <Moment format="DD MMM YYYY ,  HH:mm A">
                          {row?.createdAt}
                        </Moment>
                      )}
                      {renderTableCell(row?.amount || renderTextContent("N/A"))}
                      {renderTableCell(
                        row?.paymentMethods || renderTextContent("N/A")
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </motion.div>
          ) : (
            <p className="flex justify-center mt-[150px] text-[18px] font-[600] text-[#888888]">
              No data found
            </p>
          )}
        </TableContainer>
      )}

      <div className="flex justify-between mt-auto">
        <Select
          labelId="pagination-limit-select-label"
          value={limit}
          onChange={handlePaginationLimitChange}
          className="w-[66px] h-11 bg-[#EEEEEE] "
          style={{
            color: "#888888",
            fontSize: "14px",
            fontWeight: "500",
            width: "fit-content",
          }}>
          {limitOptions.map((options) => (
            <MenuItem
              key={options}
              style={{
                "&:hover": {
                  backgroundColor: "#EEEEEE",
                },
              }}
              value={options}>
              {options}
            </MenuItem>
          ))}
        </Select>
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(totalCount / limit)}
            defaultPage={1}
            siblingCount={3}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </Stack>
      </div>
    </Paper>
  );
}
