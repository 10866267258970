import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import CircularProgress from "@mui/material/CircularProgress";
import Trash from "../../../../Assets/images/icons/trash.png";
import Edit from "../../../../Assets/images/icons/edit-2.png";

import {
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import GenericModal from "../../../GenericModal";
import AddProduct from "./add_product";
import { dragDropProducts, fetchProduct } from "../../../API/productApi";
import { toast } from "react-toastify";
import DeleteProduct from "./delete_product";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const tableHeading = ["S. No.", "Product Id", "Product  Name", "Action"];

const limitOptions = [10, 25, 50, 75, 100];

const ProductManagement = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [addProduct, setAddProduct] = useState(false);
  const [editProduct, setEditProduct] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState(false);
  const [action, setAction] = useState(false);
  const [productData, setProductData] = useState({});
  const [totalCount, setTotalCount] = useState(1);
  const [isDragEnabled, setIsDragEnabled] = useState(false);
  const [draggedItems, setDraggedItems] = useState([]);

  useEffect(() => {
    if (action) {
      handleProductData(page, limit);
    }
  }, [action]);

  useEffect(() => {
    handleProductData(page, limit);
  }, [page, limit]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handlePaginationLimitChange = (event) => {
    setLimit(event?.target?.value);
    setPage(1);
  };

  const handleAddProduct = () => {
    setAction(false);
    setAddProduct(!addProduct);
  };

  const handleEditProduct = (data) => {
    setProductData(data);
    setAction(false);
    setEditProduct(!editProduct);
  };

  const handleDeleteProduct = (data) => {
    setProductData(data);
    setAction(false);
    setDeleteProduct(!deleteProduct);
  };

  const handleProductData = (page, limit) => {
    setLoading(true);

    fetchProduct(page, limit)
      .then((response) => {
        setTableData(response?.data?.data?.data);
        setTotalCount(response?.data?.data?.totalCount);
      })
      .catch((error) =>
        toast.error(error?.message, {
          autoClose: 5000,
          toastId: "error fetching collab post data",
        })
      )
      .finally(() => setLoading(false));
  };

  const renderTableCell = (content, style = {}) => (
    <TableCell
      style={{
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: "400",
        ...style,
      }}>
      {content}
    </TableCell>
  );

  const renderTextContent = (content, style = {}) => (
    <p
      className="w-max text-[14px] font-[600]"
      style={{
        fontFamily: "Plus Jakarta Sans",
        color: "rgba(136, 136, 136, 0.5)",
        ...style,
      }}>
      {content}
    </p>
  );

  const renderActionButtons = (row) => (
    <div className="flex gap-2">
      <button
        type="button"
        className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px]"
        onClick={() => handleEditProduct(row)}>
        <img src={Edit} alt="" className="w-5 h-5" />
      </button>
      <button
        type="button"
        className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px]"
        onClick={() => handleDeleteProduct(row)}>
        <img src={Trash} alt="" className="w-5 h-5" />
      </button>
    </div>
  );

  // drag and drop handlers

  const enableSorting = () => {
    handleProductData(1, totalCount);
    setIsDragEnabled(true); 
  };

  const handleOnDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!result.destination) return;

    const items = Array.from(tableData);

    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);
    setTableData(items);

    const updatedDraggedItems = items.map((item, index) => ({
        _id: item._id, 
        position: index + 1, 
    }));

    setDraggedItems(updatedDraggedItems);

  };


  const updateSort = () => {
    setDraggedItems([]);
    setIsDragEnabled(false);

    setLoading(true);

    const payload = {
      "updatedOrder": draggedItems
    }

    dragDropProducts(payload)
      .then((response) => {
        handleProductData(page, limit);
        toast.success('Product order updated successfully', {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        toast.error(error?.message, {
          autoClose: 5000,
          toastId: "error updating product order",
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Paper
      className=" h-[100%] max-h-[87vh] pb-6 p-6 flex flex-col gap-6 justify-between"
      style={{ borderRadius: "12px" }}
      component={Paper}>
      {addProduct && (
        <AddProduct
          setIsAddOpen={setAddProduct}
          productAdded={(e) => setAction(e)}
        />
      )}
      {editProduct && (
        <AddProduct
          setIsAddOpen={setEditProduct}
          productAdded={(e) => setAction(e)}
          productData={productData}
        />
      )}
      {!addProduct && !editProduct && (
        <>
          <div
            className="flex justify-between flex-wrap"
            style={{
              alignItems: "center",
              alignContent: "center",
            }}>
            <p
              className="text-[18px] font-[700] text-black"
              style={{ fontFamily: "Plus Jakarta Sans" }}>
              Product Management
            </p>
            <div
              className="flex gap-3 h-11 font-[600] text-[14px] "
              style={{ fontFamily: "Plus Jakarta Sans" }}>

              <button
                type="button"
                className="flex flex-row items-center  gap-1 rounded-[6px] py-[10px] px-4 bg-[#524CBB]"
                onClick={isDragEnabled ? updateSort : enableSorting}>
                <p
                  className="font-bold text-white"
                  style={{
                    fontFamily: "Plus Jakarta Sans,sans-serif",
                    lineHeight: "24px",
                    fontWeight: "500",
                    textSize: "16px",
                    padding: "0",
                  }}>
                  {isDragEnabled ? "Update Sort" : "Sort Products"}
                </p>
              </button>

              <button
                type="button"
                className="flex flex-row items-center  gap-1 rounded-[6px] py-[10px] px-4 bg-[#524CBB]"
                onClick={handleAddProduct}>
                <p
                  className="font-bold text-white"
                  style={{
                    fontFamily: "Plus Jakarta Sans,sans-serif",
                    lineHeight: "24px",
                    fontWeight: "500",
                    textSize: "16px",
                    padding: "0",
                  }}>
                  Add Product
                </p>
              </button>
            </div>
          </div>
          {loading && (
            <div className="flex items-center justify-center mt-20">
              <CircularProgress style={{ color: "#888888", opacity: "0.5" }} />
            </div>
          )}
          {!loading && (
            <TableContainer className="card w-auto overflow-auto">
              {tableData?.length !== 0 ? (
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -50 }}
                  transition={{ duration: 0.8 }}>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="products">
                      {(provided) => (
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          {...provided.droppableProps}
                          ref={provided.innerRef}>
                          <TableHead>
                            <TableRow className="w-[194px] h-11 p-3">
                              {tableHeading?.map((heading) => (
                                <TableCell key={heading} className="w-max h-11 p-3">
                                  <p
                                    key={heading}
                                    className="w-max text-[14px] font-[600] text-[#888888]"
                                    style={{ fontFamily: "Plus Jakarta Sans" }}>
                                    {heading}
                                  </p>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tableData?.map((row, index) =>
                              isDragEnabled ? (
                                // Render Draggable row when drag is enabled
                                <Draggable
                                  key={row?._id}
                                  draggableId={row?._id}
                                  index={index}>
                                  {(provided) => (
                                    <TableRow
                                      key={row?._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                      }}
                                      className="w-[194px] h-13"
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}>
                                      {renderTableCell(<p>{index + 1}</p>)}
                                      {renderTableCell(row?.productId || renderTextContent("N/A"))}
                                      {renderTableCell(
                                        row?.productName ||
                                        renderTextContent("Deleted Product", {
                                          fontStyle: "italic",
                                        })
                                      )}
                                      {renderTableCell(renderActionButtons(row))}
                                    </TableRow>
                                  )}
                                </Draggable>
                              ) : (
                                // Render normal TableRow when drag is disabled
                                <TableRow
                                  key={row?._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                  }}
                                  className="w-[194px] h-13">
                                  {renderTableCell(<p>{index + 1}</p>)}
                                  {renderTableCell(row?.productId || renderTextContent("N/A"))}
                                  {renderTableCell(
                                    row?.productName ||
                                    renderTextContent("Deleted Product", {
                                      fontStyle: "italic",
                                    })
                                  )}
                                  {renderTableCell(renderActionButtons(row))}
                                </TableRow>
                              )
                            )}
                            {provided.placeholder}
                          </TableBody>

                        </Table>
                      )}
                    </Droppable>
                  </DragDropContext>
                </motion.div>
              ) : (
                <p className="flex justify-center mt-[150px] text-[18px] font-[600] text-[#888888]">
                  No data found
                </p>
              )}
            </TableContainer>
          )}

          {deleteProduct && (
            <GenericModal
              open={deleteProduct}
              handleClose={() => setDeleteProduct(false)}
              content={
                <DeleteProduct
                  setIsDeleteOpen={setDeleteProduct}
                  productDeleted={(e) => setAction(e)}
                  productId={productData?._id}
                />
              }
              isCross={true}
              width="391px"
            />
          )}
          {!isDragEnabled &&
            <div className="flex justify-between mt-auto">
              <Select
                labelId="pagination-limit-select-label"
                value={limit}
                onChange={handlePaginationLimitChange}
                className="w-[66px] h-11 bg-[#EEEEEE]"
                style={{
                  color: "#888888",
                  fontSize: "14px",
                  fontWeight: "500",
                  width: "fit-content",
                }}>
                {limitOptions.map((options) => (
                  <MenuItem
                    key={options}
                    style={{
                      "&:hover": {
                        backgroundColor: "#EEEEEE",
                      },
                    }}
                    value={options}>
                    {options}
                  </MenuItem>
                ))}
              </Select>

              <Stack spacing={2}>
                <Pagination
                  count={Math.ceil(totalCount / limit)}
                  defaultPage={1}
                  siblingCount={3}
                  shape="rounded"
                  page={page}
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
          }
        </>
      )}
    </Paper>
  );
};

export default ProductManagement;
