import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { motion } from "framer-motion";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { MenuItem, Pagination, Select, Stack } from "@mui/material";
import Download from "../../../../Assets/images/icons/download.png";
import Eye from "../../../../Assets/images/icons/eye.png";
import Block from "../../../../Assets/images/icons/block.png";
import Premium from "../../../../Assets/images/icons/premium.png";

import Unblock from "../../../../Assets/images/icons/unblock_grey.png";
import Trash from "../../../../Assets/images/icons/trash.png";
import GenericModal from "../../../GenericModal";
import UserView from "./userView";
import Delete from "./delete";
import UserBlock from "./block";
import "../manage_admins/manageEdit.css";
import { listUserData, searchUser } from "../../../API/userManagementApi";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { setDefaults, geocode, RequestType } from "react-geocode";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import CustomDatePicker from "../../CustomDatePicker/CustomDatePicker";
import { getDateRange } from "../../CustomDatePicker/DateRangeFunction";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import API from "../../../API/urlConstants";
import UserPremium from "./userPremium";

const DateRangeFilter = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "lastYear",
    label: "Last Year",
  },
  {
    value: "lastMonth",
    label: "Last Month",
  },
  {
    value: "lastWeek",
    label: "Last Week",
  },
  {
    value: "customDateRange",
    label: "Custom Date Range",
  },
];

let title = ["View Standard Details"];
const tableHeading = [
  "S. No.",
  "User Name / Business Name",
  "Date of registration",
  "Email Address",
  "Contact Number",
  "Profile Type",
  "Location",
  "Mode",
  "Action",
];
const limitOptions = [10, 25, 50, 75, 100];
export default function UserManagementTable({ filterType, searchText }) {
  const [userId, setUserId] = useState("");
  const [userView, setUserView] = useState(false);
  const [isDel, setIsDel] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userPremium, setUserPremium] = useState(false);
  const [data, setData] = useState("")

  const [tableData, setTableData] = useState([]);
  const [isBlock, setIsBlock] = useState(false);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [yes, setYes] = useState(false);
  const { state } = useLocation();
  const [userData, setUserData] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [customTimeFilter, setCustomTimeFilter] = useState(
    state?.type === 6 ? "customDateRange" : "all"
  );
  const [addressData, setAddressData] = useState([]);
  const [delId, setDelId] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [customRange, setCustomRange] = useState(false);
  const currentDate = new Date();
  const [customDateRange, setCustomDateRange] = useState({
    startDate:
      state?.type === 6
        ? new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          0,
          0,
          0,
          0
        ).toISOString()
        : "",
    endDate: state?.type === 6 ? new Date().toISOString() : "",
  });
  const [loading, setLoading] = useState(true);

  const role = useSelector((state) => state.user.role);
  const permissions = useSelector((state) => state?.user?.permissions[0]);

  const handleTimeChange = (e) => {
    setPage(1);
    setCustomTimeFilter(e?.target?.value);
    if (e?.target?.value === "customDateRange") {
      setCustomRange(true);
    } else {
      const dateRange = getDateRange(e?.target?.value);
      setCustomRange(false);
      setCustomDateRange(dateRange);
    }
  };
  const handlePaginationLimitChange = (event) => {
    setLimit(event?.target?.value);
    setPage(1);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  setDefaults({
    key: process.env.REACT_APP_GOOGLE_API_KEY,
    language: "en",
    region: "es",
  });

  const fetchUserData = () => {
    setLoading(true);
    let initialState;
    let isBusinessValue = "";
    let businessTypeValue = "";
    switch (filterType?.value) {
      case "ALLTYPE":
        initialState = "";
        break;
      case "GIVETHANKS":
        initialState = "GIVETHANKS";
        break;
      case "INDIVIDUAL":
        initialState = "GETTHANKS";
        isBusinessValue = false;
        break;
      case "BUSINESS":
        initialState = "GETTHANKS";
        isBusinessValue = true;
        businessTypeValue = "OTHER";
        break;
      case "NGO":
        initialState = "GETTHANKS";
        isBusinessValue = true;
        businessTypeValue = "NON-PROFIT";
        break;
      default:
        initialState = "";
        break;
    }
    listUserData(
      limit,
      page,
      isBusinessValue,
      initialState,
      businessTypeValue,
      customDateRange?.startDate,
      customDateRange?.endDate
    )
      .then((response) => {
        setTableData(response?.data?.data?.userManagementData);
        setTotalCount(response?.data?.data?.userManagementDataCount);
      })
      .catch((error) =>
        toast.error(error?.message, {
          autoClose: 5000,
          toastId: "regeg",
        })
      )
      .finally(() => setLoading(false));
  };
  const handleDownloadCSV = async () => {
    try {
      const apiUrl = `${API.DOWNLOAD_CSV}?csvType=userManagement&startDate=${customDateRange?.startDate}&endDate=${customDateRange?.endDate}`;
      const response = await fetch(apiUrl);
      if (!response.ok) {
        toast.error("Failed to fetch CSV data", {
          autoClose: 5000,
        });
      }
      const csvData = await response.text();
      const blob = new Blob([csvData], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "userManagement.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error("Error downloading CSV", {
        autoClose: 5000,
      });
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setUserView(!userView);
    setIsBlockModal(!isBlockModal);
    setIsBlock(!isBlock);
    setIsDel(!isDel);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUserView(false);
    setIsBlockModal(false);
    setIsBlock(false);
    setIsDel(false);
    setUserPremium(false)
  };

  const handleUserView = (data) => {
    setUserData(data);
    handleOpenModal();
    setIsDel(false);
    setIsBlockModal(false);
    setIsBlock(false);
  };

  const handleDelete = (id) => {
    setDelId(id);
    handleOpenModal();
    setIsDel(!isDel);
    setUserView(false);
    setIsBlockModal(false);
    setIsBlock(false);
  };

  const handleBlock = (id, yes) => {
    handleOpenModal();
    setIsBlockModal(!isBlockModal);
    setIsBlock(!isBlock);
    setIsDel(false);
    setUserView(false);
    setYes(yes);
    setUserId(id);
  };

  const handleDateRange = (dateObj) => {
    setCustomDateRange(dateObj);
  };

  const handleCloseDatePicker = () => {
    setCustomRange(false);
  };
  const handleSearch = async (value) => {
    if (value !== "") {
      setLoading(true);
      searchUser(value)
        .then((response) => {
          setTableData(response?.data?.data);
          setTotalCount(0);
        })
        .catch((error) =>
          toast.error("Error fetching search results:", {
            autoClose: 5000,
            toastId: "3424",
          })
        );
      setLoading(false);
    } else if (value === "") {
      fetchUserData();
    }
  };
  const renderTableCell = (content, style = {}) => (
    <TableCell
      style={{
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: "400",
        ...style,
      }}>
      {content}
    </TableCell>
  );

  const renderTextContent = (content, style = {}) => (
    <p
      className="w-max text-[14px] font-[600]"
      style={{
        fontFamily: "Plus Jakarta Sans",
        color: "rgba(136, 136, 136, 0.5)",
        ...style,
      }}>
      {content}
    </p>
  );

  const getPreferenceLabel = (preference) => {
    switch (preference) {
      case "GIVETHANKS":
        return <p>Give Thanks</p>;
      case "GETTHANKS":
        return <p>Get Thanks</p>;
      default:
        return (
          <p
            className="w-max text-[14px] font-[600]"
            style={{
              fontFamily: "Plus Jakarta Sans",
              color: "rgba(136, 136, 136, 0.5)",
            }}>
            N/A
          </p>
        );
    }
  };

  const getLoginTypeLabel = (loginType) => {
    switch (loginType) {
      case "EMAIL":
        return <p>Email</p>;
      case "SOCIAL":
        return <p>Social Media</p>;
      case "MANUAL":
        return <p>Manual</p>;
      default:
        return (
          <p
            className="w-max text-[14px] font-[600]"
            style={{
              fontFamily: "Plus Jakarta Sans",
              color: "rgba(136, 136, 136, 0.5)",
            }}>
            N/A
          </p>
        );
    }
  };
  const handleUserPremium = (data) => {
    setData(data)
    setIsModalOpen(true)
    setUserPremium(true)

    
  }
  const renderActionButtons = (row) => (
    <div className="flex justify-between gap-2">
      <button
        type="button"
        disabled={!row?.isPremiumAddedByAdmin &&row?.premium?.isPremium  }
        className={`w-[36.8px] h-[36.8px] rounded-[5.02px] p-[10.04px] 
          
          ${row?.premium?.isPremium && row?.isPremiumAddedByAdmin ? 'bg-[#FFD700] cursor-pointer' : !row?.isPremiumAddedByAdmin &&row?.premium?.isPremium ?'bg-[#EEEEEE] cursor-no-drop':'bg-[#FFD700] cursor-pointer'}`} onClick={() => handleUserPremium(row)}>
        <img src={Premium} alt="" className="w-5 h-5" />
      </button>
      <button
        type="button"
        className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px]"
        onClick={() => handleUserView(row)}>
        <img src={Eye} alt="" className="w-5 h-5" />
      </button>
      {(role === 0 || permissions?.USER_MANAGEMENT?.fullAccess) && (
        <button
          type="button"
          className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px]"
          onClick={() => handleBlock(row?._id, row?.isBlocked)}>
          {row?.isBlocked ? (
            <img src={Unblock} alt="" className="w-5 h-5" />
          ) : (
            <img src={Block} alt="" className="w-5 h-5" />
          )}
        </button>
      )}
      {(role === 0 || permissions?.USER_MANAGEMENT?.fullAccess) && (
        <button
          type="button"
          className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px]"
          onClick={() => handleDelete(row?._id)}>
          <img src={Trash} alt="" className="w-5 h-5" />
        </button>
      )}
    </div>
  );

  useEffect(() => {
    const fetchAddresses = async () => {
      const updatedAddresses = await Promise.all(
        tableData.map(async (row) => {
          if (
            row?.userLocation?.coordinates[1] &&
            row?.userLocation?.coordinates[0]
          ) {
            try {
              const response = await geocode(
                RequestType.LATLNG,
                `${row?.userLocation?.coordinates[1]},
             ${row?.userLocation?.coordinates[0]}`
              );
              const address = response.results[0].formatted_address;
              return address;
            } catch (error) {
              return "";
            }
          }
        })
      );

      setAddressData(updatedAddresses);
    };

    fetchAddresses();
  }, [tableData]);
  useEffect(() => {
    if (isDeleted) {
      fetchUserData();
      setIsDeleted(false);
    }
    if (isBlocked) {
      fetchUserData();
      setIsBlocked(false);
    }
  }, [isDeleted, isBlocked]);
  useEffect(() => {
    if (searchText) {
      handleSearch(searchText);
      return;
    }
    fetchUserData();
  }, [state, limit, page, filterType?.value, customDateRange, searchText]);
  return (
    <Paper
      className="randomClass1 flex-grow  flex flex-col justify-between gap-6 p-6 mb-6"
      style={{ borderRadius: "12px", justifyContent: "flex-start" }}
      component={Paper}>
      <div
        className="flex justify-between flex-wrap"
        style={{
          alignItems: "center",
          alignContent: "center",
        }}>
        <p
          className="text-[18px] font-[700] text-black"
          style={{ fontFamily: "Plus Jakarta Sans" }}>
          {filterType.label}
        </p>

        <div
          className="flex gap-3 h-11 font-[600] text-[14px] "
          style={{ fontFamily: "Plus Jakarta Sans" }}>
          <Select
            labelId="pagination-limit-select-label"
            id="pagination-limit-select"
            value={customTimeFilter || "all"}
            displayEmpty
            onChange={(e) => {
              handleTimeChange(e);
            }}
            className="flex justify-between items-center  rounded-[6px] min-w-[110px] gap-1 py-2 px-[14px] bg-[#EEEEEE]"
            style={{
              fontFamily: "Inter",
              lineHeight: "20px",
              fontWeight: "500",
              textSize: "14px",
              padding: "0",
              color: "#888888",
            }}>
            {DateRangeFilter.map((item, index) => (
              <MenuItem
                key={item?.label}
                style={{
                  "&:hover": {
                    backgroundColor: "#EEEEEE",
                  },
                }}
                value={item?.value}
                onClick={(e) => {
                  if (e?.target?.outerText === "Custom Date Range") {
                    setCustomRange(true);
                  }
                }}>
                {item?.label}
              </MenuItem>
            ))}
          </Select>
          {customRange && (
            <CustomDatePicker
              right={"180px"}
              top={"120px"}
              DateRange={customDateRange}
              getDateRange={(e) => handleDateRange(e)}
              onClose={handleCloseDatePicker}
            />
          )}

          <button
            type="button"
            className="flex flex-row justify-between items-center w-[174px] gap-1 rounded-[6px] py-[10px] px-4 bg-[#524CBB]"
            onClick={handleDownloadCSV}>
            <p
              className="font-bold text-white"
              style={{
                fontFamily: "Plus Jakarta Sans,sans-serif",
                lineHeight: "24px",
                fontWeight: "500",
                textSize: "16px",
                padding: "0",
              }}>
              Download CSV
            </p>
            <img
              src={Download}
              alt=""
              className="flex justify-center items-center m-auto w-[18.32px] h-[18.32px]"
            />
          </button>
        </div>
      </div>
      {loading && (
        <div className="flex items-center justify-center mt-20">
          <CircularProgress style={{ color: "#888888", opacity: "0.5" }} />
        </div>
      )}
      {!loading && (
        <TableContainer className="card w-auto overflow-auto">
          {tableData?.length !== 0 ? (
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.8 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="w-[194px] h-11 p-3">
                    {tableHeading.map((heading) => (
                      <TableCell key={heading} className="w-max h-11 p-3">
                        <p
                          key={heading}
                          className="w-max text-[14px] font-[600] text-[#888888]"
                          style={{ fontFamily: "Plus Jakarta Sans" }}>
                          {heading}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tableData?.map((row, index) => (
                    <TableRow
                      key={row?._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      className="w-[194px] h-13">
                      {renderTableCell(<p>{index + 1}</p>)}
                      {renderTableCell(
                        (row?.isWorkPlace
                          ? row?.workPlace
                          : row?.userName || row?.fullName) ||
                        renderTextContent("N/A")
                      )}
                      {renderTableCell(
                        <Moment format="DD MMM, YYYY">{row?.createdAt}</Moment>
                      )}
                      {renderTableCell(row?.email || renderTextContent("N/A"))}
                      {renderTableCell(
                        row?.mobileNumber || renderTextContent("N/A")
                      )}
                      {renderTableCell(getPreferenceLabel(row?.preference))}
                      {renderTableCell(
                        addressData[index] || renderTextContent("N/A"),
                        {
                          maxWidth: "169px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          width: "92%",
                        }
                      )}
                      {renderTableCell(getLoginTypeLabel(row?.signupType))}
                      {renderTableCell(renderActionButtons(row))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </motion.div>
          ) : (
            <p className="flex justify-center mt-[150px] text-[18px] font-[600] text-[#888888]">
              No data found
            </p>
          )}
        </TableContainer>
      )}
      {userView && (
        <GenericModal
          open={isModalOpen}
          title={title[0]}
          handleClose={handleCloseModal}
          content={<UserView userData={userData} />}
          isCross={true}
          width={"631px"}
        />
      )}


      {userPremium && (
        <GenericModal
          open={userPremium}
          title={"Make Premium"}
          handleClose={handleCloseModal}
          content={<UserPremium userData={data} handleCloseModal={handleCloseModal} fetchUserData={fetchUserData}/>}
          isCross={true}
          width={"470px"}
        />
      )}
      {isDel && (
        <GenericModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          content={
            <Delete
              setIsDeleted={setIsDeleted}
              setIsOpenModal={setIsModalOpen}
              id={delId}
              userType={"users"}
            />
          }
          isCross={false}
          width={"391px"}
          height={"328px"}
        />
      )}

      {isBlockModal && (
        <GenericModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          content={
            <UserBlock
              setIsBlocked={setIsBlocked}
              userId={userId}
              setIsOpenModal={setIsModalOpen}
              yes={yes}
              userType={"user"}
            />
          }
          isCross={false}
          width={"391px"}
          height={yes ? "270px" : "308px"}
        />
      )}

      <div className="flex justify-between mt-auto">
        <Select
          labelId="pagination-limit-select-label"
          value={limit}
          onChange={handlePaginationLimitChange}
          className="w-[66px] h-11 bg-[#EEEEEE] "
          style={{
            color: "#888888",
            fontSize: "14px",
            fontWeight: "500",
            width: "fit-content",
          }}>
          {limitOptions.map((options) => (
            <MenuItem
              key={options}
              style={{
                "&:hover": {
                  backgroundColor: "#EEEEEE",
                },
              }}
              value={options}>
              {options}
            </MenuItem>
          ))}
        </Select>
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(totalCount / limit)}
            defaultPage={1}
            siblingCount={3}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </Stack>
      </div>
    </Paper>
  );
}
UserManagementTable.propTypes = {
  filterType: PropTypes.object,
  searchText: PropTypes.string,
};
