import React from 'react';
import Trash from "../../../../Assets/images/icons/trash_white.png";
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { cancelPremium, reviewActions } from '../../../API/reviewManagementApi';
import { Modal } from '@mui/material';

const DeleteUserPrem = ({ setIsOpenModal, isOpenModal, setIsModal, setIsDelete, data ,handleCloseModal}) => {
    const handleClose = () => {
        setIsOpenModal(false);
    };

    const handleDelete = () => {
        if (!data?._id) {
            toast.error("Invalid data provided", { autoClose: 5000 });
            return;
        }

        const postForm = {
            userId: data?._id        };

        cancelPremium(postForm)
            .then((response) => {
                // setIsDelete(true);
                toast.error(response?.data?.data?.messages, { autoClose: 5000, toastId: "rgevx" });
                handleClose();
                handleCloseModal()
                setIsOpenModal(false);
            })
            .catch((error) => 
                toast.error(error?.message || "Failed to delete review", { autoClose: 5000, toastId: "ef" })
            );
    };

    return (
        <Modal open={isOpenModal} onClose={handleClose}>
            <div className="flex flex-col items-center justify-center min-h-screen">
                <div className="bg-white p-6 rounded-lg shadow-lg w-[343px]">
                    <div className="flex flex-col items-center gap-5">
                        {/* Trash Icon */}
                        <div className="flex items-center justify-center w-[78px] h-[78px] rounded-full bg-[#D33939]">
                            <img src={Trash} alt="Delete" className="w-[40px] h-[40px]" />
                        </div>

                        {/* Modal Text */}
                        <h2 className="font-bold text-center text-lg">
                            Are you sure you want to cancel this premium?
                        </h2>
                        <p className="text-sm text-gray-500 text-center">
                            Are you sure you want to cancel this premium from the system?
                        </p>

                        {/* Buttons */}
                        <div className="flex gap-4 mt-4">
                            <button className="px-4 py-2 bg-gray-300 rounded-md" onClick={handleClose}>
                                Cancel
                            </button>
                            <button className="px-4 py-2 bg-[#524CBB] text-white rounded-md" onClick={handleDelete}>
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

DeleteUserPrem.propTypes = {
    setIsOpenModal: PropTypes.func.isRequired,
    isOpenModal: PropTypes.bool.isRequired,
    setIsModal: PropTypes.func.isRequired,
    setIsDelete: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
};

export default DeleteUserPrem;
