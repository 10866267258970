import React, { useEffect, useState } from "react";

import {
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "../../../../../Assets/images/icons/search.png";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { motion } from "framer-motion";
import Moment from "react-moment";
import { financeData } from "../../../../API/financeApi";
import { toast } from "react-toastify";
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  zIndex: "1",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "30px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {},
  },
}));
const tableHeading = [
  "S. No.",
  "Transaction ID",
  "Date and Time",
  "Payer's Name",
  "Receiver Name",
  "Amount",
  "Mode of Transaction",
  "Status",
];
const limitOptions = [10, 25, 50, 75, 100];

const GratiTipDetails = () => {
  const isSearchFilled = "";
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const renderTableCell = (content, style = {}) => (
    <TableCell
      style={{
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: "400",
        ...style,
      }}>
      {content}
    </TableCell>
  );

  const renderTextContent = (content, style = {}) => (
    <p
      className="w-max text-[14px] font-[600]"
      style={{
        fontFamily: "Plus Jakarta Sans",
        color: "rgba(136, 136, 136, 0.5)",
        ...style,
      }}>
      {content}
    </p>
  );
  const getPreferenceLabel = (preference) => {
    switch (preference) {
      case "GIVETHANKS":
        return <p>Give Thanks</p>;
      case "GETTHANKS":
        return <p>Get Thanks</p>;
      default:
        return (
          <p
            className="w-max text-[14px] font-[600]"
            style={{
              fontFamily: "Plus Jakarta Sans",
              color: "rgba(136, 136, 136, 0.5)",
            }}>
            N/A
          </p>
        );
    }
  };
  const paymentMethodType = (data) => {
    return data
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const handlePaginationLimitChange = (event) => {
    setLimit(event?.target?.value);
    setPage(1);
  };
  const handlePageChange = (event, page) => {
    setPage(page);
  };
  const fetchTransactionDetails = () => {
    setLoading(true);
    financeData("", "", limit, page, "")
      .then((response) => {
        setTableData(response?.data?.data?.response);
        setTotalCount(response?.data?.data?.count);
      })
      .catch((error) => {
        setTableData([]);
        toast.error(error?.messsage, {
          autoClose: 5000,
          toastId: "eError fetchiing grati tip detail",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchTransactionDetails();
  }, [limit, page]);
  return (
    <Paper
      className="h-[100%] max-h-[87vh] flex flex-col justify-between gap-6 p-6 mb-6"
      style={{ borderRadius: "12px", justifyContent: "flex-start" }}
      component={Paper}>
      <div className="flex justify-between" style={{ alignItems: "baseline" }}>
        <p
          className="text-[18px] font-[700] text-black"
          style={{ fontFamily: "Plus Jakarta Sans" }}>
          Grati Tip Details
        </p>
        <div
          className="flex gap-3 h-11 font-[600] text-[14px] "
          style={{ fontFamily: "Plus Jakarta Sans" }}>
          <Search className="flex flex-row justify-between relative !mr-0">
            <SearchIconWrapper>
              <img src={SearchIcon} alt="" className="w-[22px] h-[22px]" />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search By Transaction Id"
              inputProps={{ "aria-label": "search" }}
              className="flex w-[272px] rounded-[6px] gap-1 border-[1px] p-[10px] bg-[#EEEEEE]"
            />
            {isSearchFilled && (
              <button
                className="bg-[#EEEEEE] outline-none px-2 rounded-r-[6px] absolute right-0 top-[12px]"
                // onClick={handleSearchClear}
              >
                X
              </button>
            )}
          </Search>
        </div>
      </div>
      {loading && (
        <div className="flex items-center justify-center mt-20">
          <CircularProgress style={{ color: "#888888", opacity: "0.5" }} />
        </div>
      )}
      {!loading && (
        <TableContainer className="card overflow-auto">
          {tableData?.length !== 0 ? (
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.8 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className="w-[194px] h-11 p-3">
                    {tableHeading.map((heading) => (
                      <TableCell key={heading} className="w-max h-11 p-3">
                        <p
                          className="w-max text-[14px] font-[600] text-[#888888]"
                          style={{ fontFamily: "Plus Jakarta Sans" }}>
                          {heading}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tableData?.map((row, index) => (
                    <TableRow
                      key={row?._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      className="w-[fit] h-13">
                      {renderTableCell(index + 1)}
                      {renderTableCell(
                        row?.transactionId ? (
                          <p>{row?.transactionId}</p>
                        ) : (
                          renderTextContent("N/A", { fontWeight: "600" })
                        )
                      )}
                      {renderTableCell(
                        <Moment format="DD MMM YYYY ,  HH:mm A" withTitle>
                          {row?.createdAt}
                        </Moment>
                      )}
                      {renderTableCell(
                        row?.payeeDetails?.userName ? (
                          <p>{row?.payeeDetails?.userName}</p>
                        ) : (
                          renderTextContent("Deleted User", {
                            fontStyle: "italic",
                          })
                        )
                      )}
                      {renderTableCell(
                        row?.payeeDetails?.preference
                          ? getPreferenceLabel(row?.payeeDetails?.preference)
                          : renderTextContent("Deleted User", {
                              fontStyle: "italic",
                            })
                      )}
                      {renderTableCell(
                        row?.amount ? (
                          <p>$ {row?.amount}</p>
                        ) : (
                          renderTextContent("N/A")
                        )
                      )}
                      {renderTableCell(
                        row?.paymentMethod
                          ? paymentMethodType(row?.paymentMethod)
                          : renderTextContent("N/A")
                      )}
                      {renderTableCell(
                        row?.paymentStatus ? (
                          <p>
                            {row?.paymentStatus.charAt(0).toUpperCase() +
                              row?.paymentStatus.slice(1)}
                          </p>
                        ) : (
                          renderTextContent("N/A")
                        )
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </motion.div>
          ) : (
            <p className="flex justify-center mt-[150px] text-[18px] font-[600] text-[#888888]">
              No data found
            </p>
          )}
        </TableContainer>
      )}
      <div className="flex justify-between mt-auto">
        <Select
          labelId="pagination-limit-select-label"
          value={limit}
          onChange={handlePaginationLimitChange}
          className="w-[66px] h-11 bg-[#EEEEEE] "
          style={{
            color: "#888888",
            fontSize: "14px",
            fontWeight: "500",
            width: "fit-content",
          }}>
          {limitOptions.map((options) => (
            <MenuItem
              key={options}
              style={{
                "&:hover": {
                  backgroundColor: "#EEEEEE",
                },
              }}
              value={options}>
              {options}
            </MenuItem>
          ))}
        </Select>

        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(totalCount / limit)}
            defaultPage={1}
            siblingCount={3}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </Stack>
      </div>
    </Paper>
  );
};

export default GratiTipDetails;
