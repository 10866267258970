export const mockDataset = [
    {
        countryCode
            :
            "+91",
        createdAt
            :
            "2024-01-24T09:28:38.106Z",
        email
            :
            "dwivedishatakshi2000@gmail.com",
        fullName
            :
            "Soniya Joshi",
        isBlocked
            :
            false,
        loginType
            :
            "EMAIL_PASSWORD",
        mobileNumber
            :
            "8860020925",
        preference
            :
            "GIVETHANKS",
        profilePicture
            :
            "",
        signupType
            :
            "SOCIAL",
        userLocation
            :
            { coordinates: [77.3670973, 28.6121321] },
        userName
            :
            "soniya@12",
        _id
            :
            "65b0d846ea0669c11284725f",
        media: [
            {
                imgUrl: "https://graticommunity.s3.amazonaws.com/compressedFiles/1709117908731/CD8CC94D-120B-49F7-A3A8-3DC4E5ACD265.jpg",
                mimeType: "image/jpeg"
            }
        ]
    },
    {
        countryCode
            :
            "+91",
        createdAt
            :
            "2024-01-24T09:28:38.106Z",
        email
            :
            "dwivedishatakshi2000@gmail.com",
        fullName
            :
            "Soniya Joshi",
        isBlocked
            :
            false,
        loginType
            :
            "EMAIL_PASSWORD",
        mobileNumber
            :
            "8860020925",
        preference
            :
            "GIVETHANKS",
        profilePicture
            :
            "",
        signupType
            :
            "SOCIAL",
        userLocation
            :
            { coordinates: [77.3670973, 28.6121321] },
        userName
            :
            "soniya@12",
        _id
            :
            "65b0d846ea0669c11284725f",
    },
    {
        countryCode
            :
            "+91",
        createdAt
            :
            "2024-01-24T09:28:38.106Z",
        email
            :
            "dwivedishatakshi2000@gmail.com",
        fullName
            :
            "Soniya Joshi",
        isBlocked
            :
            false,
        loginType
            :
            "EMAIL_PASSWORD",
        mobileNumber
            :
            "8860020925",
        preference
            :
            "GIVETHANKS",
        profilePicture
            :
            "",
        signupType
            :
            "SOCIAL",
        userLocation
            :
            { coordinates: [77.3670973, 28.6121321] },
        userName
            :
            "soniya@12",
        _id
            :
            "65b0d846ea0669c11284725f",
        media: [
            {
                imgUrl: "https://graticommunity.s3.amazonaws.com/compressedFiles/1709117908731/CD8CC94D-120B-49F7-A3A8-3DC4E5ACD265.jpg",
                mimeType: "image/jpeg"
            }
        ]
    },
    {
        countryCode
            :
            "+91",
        createdAt
            :
            "2024-01-24T09:28:38.106Z",
        email
            :
            "dwivedishatakshi2000@gmail.com",
        fullName
            :
            "Soniya Joshi",
        isBlocked
            :
            false,
        loginType
            :
            "EMAIL_PASSWORD",
        mobileNumber
            :
            "8860020925",
        preference
            :
            "GIVETHANKS",
        profilePicture
            :
            "",
        signupType
            :
            "SOCIAL",
        userLocation
            :
            { coordinates: [77.3670973, 28.6121321] },
        userName
            :
            "soniya@12",
        _id
            :
            "65b0d846ea0669c11284725f",
        media: [
            {
                imgUrl: "https://graticommunity.s3.amazonaws.com/compressedFiles/1709117908731/CD8CC94D-120B-49F7-A3A8-3DC4E5ACD265.jpg",
                mimeType: "image/jpeg"
            }
        ]
    },
    {
        countryCode
            :
            "+91",
        createdAt
            :
            "2024-01-24T09:28:38.106Z",
        email
            :
            "dwivedishatakshi2000@gmail.com",
        fullName
            :
            "Soniya Joshi",
        isBlocked
            :
            false,
        loginType
            :
            "EMAIL_PASSWORD",
        mobileNumber
            :
            "8860020925",
        preference
            :
            "GIVETHANKS",
        profilePicture
            :
            "",
        signupType
            :
            "SOCIAL",
        userLocation
            :
            { coordinates: [77.3670973, 28.6121321] },
        userName
            :
            "soniya@12",
        _id
            :
            "65b0d846ea0669c11284725f",
        media: [
            {
                imgUrl: "https://graticommunity.s3.amazonaws.com/compressedFiles/1709117908731/CD8CC94D-120B-49F7-A3A8-3DC4E5ACD265.jpg",
                mimeType: "image/jpeg"
            }
        ]
    },
    {
        countryCode
            :
            "+91",
        createdAt
            :
            "2024-01-24T09:28:38.106Z",
        email
            :
            "dwivedishatakshi2000@gmail.com",
        fullName
            :
            "Soniya Joshi",
        isBlocked
            :
            false,
        loginType
            :
            "EMAIL_PASSWORD",
        mobileNumber
            :
            "8860020925",
        preference
            :
            "GIVETHANKS",
        profilePicture
            :
            "",
        signupType
            :
            "SOCIAL",
        userLocation
            :
            { coordinates: [77.3670973, 28.6121321] },
        userName
            :
            "soniya@12",
        _id
            :
            "65b0d846ea0669c11284725f",
        media: [
            {
                imgUrl: "https://graticommunity.s3.amazonaws.com/compressedFiles/1709117908731/CD8CC94D-120B-49F7-A3A8-3DC4E5ACD265.jpg",
                mimeType: "image/jpeg"
            }
        ]
    },
    {
        countryCode
            :
            "+91",
        createdAt
            :
            "2024-01-24T09:28:38.106Z",
        email
            :
            "dwivedishatakshi2000@gmail.com",
        fullName
            :
            "Soniya Joshi",
        isBlocked
            :
            false,
        loginType
            :
            "EMAIL_PASSWORD",
        mobileNumber
            :
            "8860020925",
        preference
            :
            "GIVETHANKS",
        profilePicture
            :
            "",
        signupType
            :
            "SOCIAL",
        userLocation
            :
            { coordinates: [77.3670973, 28.6121321] },
        userName
            :
            "soniya@12",
        _id
            :
            "65b0d846ea0669c11284725f",
        media: [
            {
                imgUrl
                    :
                    "https://graticommunity.s3.amazonaws.com/Uploads/1705831206254/1000001010",
                mimeType
                    :
                    "video/mp4",
                thumbNail
                    :
                    "https://graticommunity.s3.amazonaws.com/Uploads/1705831217221/image.jpg",
            }
        ]
    },
    {
        countryCode
            :
            "+91",
        createdAt
            :
            "2024-01-24T09:28:38.106Z",
        email
            :
            "dwivedishatakshi2000@gmail.com",
        fullName
            :
            "Soniya Joshi",
        isBlocked
            :
            false,
        loginType
            :
            "EMAIL_PASSWORD",
        mobileNumber
            :
            "8860020925",
        preference
            :
            "GIVETHANKS",
        profilePicture
            :
            "",
        signupType
            :
            "SOCIAL",
        userLocation
            :
            { coordinates: [77.3670973, 28.6121321] },
        userName
            :
            "soniya@12",
        _id
            :
            "65b0d846ea0669c11284725f",
        media: [
            {
                imgUrl
                    :
                    "https://graticommunity.s3.amazonaws.com/Uploads/1705831206254/1000001010",
                mimeType
                    :
                    "video/mp4",
                thumbNail
                    :
                    "https://graticommunity.s3.amazonaws.com/Uploads/1705831217221/image.jpg",
            }
        ]
    },
    {
        countryCode
            :
            "+91",
        createdAt
            :
            "2024-01-24T09:28:38.106Z",
        email
            :
            "dwivedishatakshi2000@gmail.com",
        fullName
            :
            "Soniya Joshi",
        isBlocked
            :
            false,
        loginType
            :
            "EMAIL_PASSWORD",
        mobileNumber
            :
            "8860020925",
        preference
            :
            "GIVETHANKS",
        profilePicture
            :
            "",
        signupType
            :
            "SOCIAL",
        userLocation
            :
            { coordinates: [77.3670973, 28.6121321] },
        userName
            :
            "soniya@12",
        _id
            :
            "65b0d846ea0669c11284725f",
        media: [
            {
                imgUrl
                    :
                    "https://graticommunity.s3.amazonaws.com/Uploads/1705831206254/1000001010",
                mimeType
                    :
                    "video/mp4",
                thumbNail
                    :
                    "https://graticommunity.s3.amazonaws.com/Uploads/1705831217221/image.jpg",
            }
        ]
    },
    {
        countryCode
            :
            "+91",
        createdAt
            :
            "2024-01-24T09:28:38.106Z",
        email
            :
            "dwivedishatakshi2000@gmail.com",
        fullName
            :
            "Soniya Joshi",
        isBlocked
            :
            false,
        loginType
            :
            "EMAIL_PASSWORD",
        mobileNumber
            :
            "8860020925",
        preference
            :
            "GIVETHANKS",
        profilePicture
            :
            "",
        signupType
            :
            "SOCIAL",
        userLocation
            :
            { coordinates: [77.3670973, 28.6121321] },
        userName
            :
            "soniya@12",
        _id
            :
            "65b0d846ea0669c11284725f",
        media: [
            {
                imgUrl: "https://graticommunity.s3.amazonaws.com/compressedFiles/1706778116245/rn_image_picker_lib_temp_350b56dd-3096-4a72-912b-83acfbdf7738.jpg",
                mimeType: "image/jpeg"
            }
        ]
    },
    {
        countryCode
            :
            "+91",
        createdAt
            :
            "2024-01-24T09:28:38.106Z",
        email
            :
            "dwivedishatakshi2000@gmail.com",
        fullName
            :
            "Soniya Joshi",
        isBlocked
            :
            false,
        loginType
            :
            "EMAIL_PASSWORD",
        mobileNumber
            :
            "8860020925",
        media: [
            {
                imgUrl: "https://graticommunity.s3.amazonaws.com/Uploads/1706699725112/IMG_3456.mov",
                mimeType: "video/quicktime",
                thumbNail: "https://graticommunity.s3.amazonaws.com/Uploads/1706699741008/image.jpg"
            }
        ],
        preference
            :
            "GIVETHANKS",
        profilePicture
            :
            "",
        signupType
            :
            "SOCIAL",
        userLocation
            :
            { coordinates: [77.3670973, 28.6121321] },
        userName
            :
            "soniya@12",
        _id
            :
            "65b0d846ea0669c11284725f",
    },

]