import axios from "./axiosConfig";
import API from "./urlConstants";
import { redirect } from "react-router-dom";

export async function wrappedFetch(url, method, data, headers, configs) {
    let config = {
        url,
        method,
        data,
        ...configs,
    };
    if (headers) config.headers = headers;
    try {
        let result = await axios(config);
        return result;
    } catch (err) {
        if (err?.response?.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            if (localStorage.getItem(API.AUTH_TOKEN) == null)
                redirect("/");
        }
        throw new Error(err?.response?.data?.message ? err?.response?.data?.message : "Failed to fetch data , some error occured");
    }
}

export async function wrappedGet(url, method, params) {
    let config = {
        url,
        method,
        headers: {},
        params,
    };

    try {
        return await axios(config);
    } catch (err) {
        if (err?.response?.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            if (localStorage.getItem(API.AUTH_TOKEN) == null)
                redirect("/");
        }
        throw new Error(err?.response?.data?.message ? err?.response?.data?.message : "Failed to fetch data , some error occured");
    }
}


export async function wrappedPost(url, data, headers = {}) {
    let config = {
        url,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
        data,
    };

    try {
        return await axios(config);
    } catch (err) {
        if (err?.response?.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            if (localStorage.getItem(API.AUTH_TOKEN) == null) redirect("/");
        }
        throw new Error(
            err?.response?.data?.message
                ? err.response.data.message
                : "Failed to send data, some error occurred"
        );
    }
}
