import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import "./manageEdit.css";
import { createSubAdmin } from "../../../API/manageAdminApi";
import "./manage.css";
import { styled } from '@mui/material/styles';
import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 26,
  height: 16,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
const ManageAdd = ({ setIsAdded, setIsOpenModal }) => {

  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    role: "",
    password: "",
    permissions: { DASHBOARD: { limited: false, fullAccess: false }, USER_MANAGEMENT: { limited: false, fullAccess: false }, REVIEW_AND_FEEDBACK: { limited: false, fullAccess: false }, FINANCE: false, SUB_ADMIN_MANAGEMENT: false },
  });



  const [errors, setErrors] = useState({
    userName: "",
  });
  const [isLimitedView, setIsLimitedView] = useState(false);
  const [isFullView, setIsFullView] = useState(false);
  const [isUserFull, setIsUserFull] = useState(false);
  const [isUserView, setIsUserView] = useState(false);
  const [isLimitRev, setIsLimitRev] = useState(false);
  const [isFullRev, setIsFullRev] = useState(false);
  const [isSubAdmin, setIsSubAdmin] = useState(false);
  const [isFinance, setIsFinance] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const passwordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const handleLimitedViewChange = () => {

    setIsLimitedView((prev) => !prev);
    setFormData((prevData) => ({
      ...prevData,
      permissions: {
        ...prevData?.permissions,
        DASHBOARD: {
          limited: !(prevData?.permissions?.DASHBOARD?.limited),
          fullAccess: false
        }
      },
    }));
    setIsFullView(false); // Turn off Full View when Limited View is turned on
  };

  const handleFullViewChange = () => {
    setIsFullView((prev) => !prev);
    setFormData((prevData) => ({
      ...prevData,
      permissions: {
        ...prevData?.permissions,
        DASHBOARD: {
          limited: false,
          fullAccess: !prevData?.permissions?.DASHBOARD?.fullAccess,
        }
      },
    }));
    setIsLimitedView(false); // Turn off Limited View when Full View is turned on
  };
  const handleViewAccessChange = () => {
    setIsUserView((prev) => !prev);
    setFormData((prevData) => ({
      ...prevData,
      permissions: {
        ...prevData?.permissions,
        USER_MANAGEMENT: {
          limited: !prevData?.permissions?.USER_MANAGEMENT?.limited,
          fullAccess: false
        }
      },
    }));
    setIsUserFull(false); // Turn off Limited View when Full View is turned on
  };
  const handleFullAccessChange = () => {
    setIsUserFull((prev) => !prev);
    setFormData((prevData) => ({
      ...prevData,
      permissions: {
        ...prevData?.permissions,
        USER_MANAGEMENT: {
          fullAccess: !prevData?.permissions?.USER_MANAGEMENT?.fullAccess,
          limited: false
        }
      },
    }));
    setIsUserView(false); // Turn off Limited View when Full View is turned on
  };
  const handleFullRevChange = () => {
    setIsFullRev((prev) => !prev);
    setFormData((prevData) => ({
      ...prevData,
      permissions: {
        ...prevData?.permissions,
        REVIEW_AND_FEEDBACK: {
          fullAccess: !prevData?.permissions?.REVIEW_AND_FEEDBACK?.fullAccess,
          limited: false
        }
      },
    }));
    setIsLimitRev(false); // Turn off Limited View when Full View is turned on
  };
  const handleLimitRevChange = () => {
    setIsLimitRev((prev) => !prev);
    setFormData((prevData) => ({
      ...prevData,
      permissions: {
        ...prevData?.permissions,
        REVIEW_AND_FEEDBACK: {
          limited: !prevData?.permissions?.REVIEW_AND_FEEDBACK?.limited,
          fullAccess: false

        }
      },
    }));
    setIsFullRev(false); // Turn off Limited View when Full View is turned on
  };

  const handleSubAdmin = () => {
    setIsSubAdmin((prev) => !prev);
    setFormData((prevData) => ({
      ...prevData,
      permissions: {

        ...prevData.permissions,
        SUB_ADMIN_MANAGEMENT: !prevData?.permissions?.SUB_ADMIN_MANAGEMENT, // Toggle the limitedView property
      },
    }));
  };
  const handleFinance = () => {
    setIsFinance((prev) => !prev);
    setFormData((prevData) => ({
      ...prevData,
      permissions: {

        ...prevData.permissions,
        FINANCE: !prevData?.permissions?.FINANCE, // Toggle the limitedView property
      },
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };


  const handleClose = () => {
    setIsOpenModal(false);
  };

  const validateForm = () => {
    if (!formData.userName) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        userName: "Username is required",
      }));
      return false;
    }

    if (!formData.email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required",
      }));
      return false;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email format",
      }));
      return false;
    }
    if (!formData.role) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        role: "Role is required",
      }));
      return false;
    }
    if (!formData.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password is required",
      }));
      return false;
    }

    if (
      !/[A-Z]/.test(formData.password) ||
      !/[a-z]/.test(formData.password) ||
      !/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(formData.password)
      ||
      formData.password.length < 8 ||
      formData.password.length > 16
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password:
          "Password must be 8-16 characters long and contain at least one uppercase letter, one lowercase letter, one special character, and one number",
      }));
      return false;
    }



    if (!formData.permissions.DASHBOARD.fullAccess && !formData.permissions.DASHBOARD.limited) {
      toast.error("Please provide access to dashboard", { autoClose: 5000 });
      return false;
    }

    if (!formData.permissions.USER_MANAGEMENT.fullAccess && !formData.permissions.USER_MANAGEMENT.limited) {
      toast.error("Please provide access to user management", { autoClose: 5000 });
      return false;
    }

    if (!formData.permissions.REVIEW_AND_FEEDBACK.fullAccess && !formData.permissions.REVIEW_AND_FEEDBACK.limited) {
      toast.error("Please provide access to review management", { autoClose: 5000 });
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    const data = {
      fullName: formData.userName,
      email: formData.email,
      role: formData.role,
      password: formData.password,
      permissionsObj: formData.permissions,
    };

    createSubAdmin(data)
      .then((res) => {
        if (formData.role == 1)
          toast.success("Admin  account created Successfully", {
            autoClose: 5000
          });
        if (formData.role == 2)
          toast.success("Sub Admin account created Successfully", {
            autoClose: 5000
          });
        setIsAdded(true);
        handleClose();
      })
      .catch((error) => toast.error(error.message, { autoClose: 5000 }));
  };


  return (
    <div>
      <div

        className="card h-[570px] w-[585px] flex flex-col gap-6 font-semibold"
      >
        <div className=" flex w-[570px] h-auto gap-6">
          <div className="flex flex-col w-[273px] h-18  gap-[6px]">
            <p
              className=" w-fit h-6 text-black text-4 font-semibold"
              style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
            >
              User Name
            </p>
            <TextField

              defaultValue=""
              placeholder="Enter Name"
              name="userName"
              value={formData?.userName}
              onChange={handleInputChange}
              error={Boolean(errors?.userName)}
              helperText={errors?.userName}
              className="w-[273px] h-11"
            />
          </div>
          <div className="flex flex-col w-[260.5px] h-18  gap-1">
            <p
              className=" w-fit h-6 text-black text-4 font-semibold"
              style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
            >
              Email Address
            </p>
            <TextField

              placeholder="Enter Email Address "
              name="email"
              type="email"
              value={formData?.email}
              onChange={handleInputChange}
              className="w-[273px] h-11"
              error={Boolean(errors?.email)}
              helperText={errors?.email}
            />
          </div>
        </div>
        <div className=" flex w-[570px] h-auto gap-6 mt-2">
          <div className="flex flex-col w-[570x] h-18 gap-1">
            <p
              className=" w-fit h-6 text-black text-4 font-semibold"
              style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
            >
              Admin Role
            </p>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formData?.role}
              name="role"
              displayEmpty
              placeholder="Select Role"
              onChange={handleInputChange}
              error={Boolean(errors?.role)}
              helperText={errors?.role}
              className="w-[273px] h-18"
            >

              <MenuItem style={{
                '&:hover': {
                  backgroundColor: '#EEEEEE',
                },
              }} value={1}>Admin</MenuItem>
              <MenuItem style={{
                '&:hover': {
                  backgroundColor: '#EEEEEE',
                },
              }} value={2}>Sub-Admin</MenuItem>
            </Select>
          </div>
          <div className="flex flex-col w-[260.5px] h-18  gap-1 relative ">
            <p
              className=" w-fit h-6 text-black text-4 font-semibold"
              style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
            >
              Password
            </p>
            <TextField
              placeholder="Create Password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData?.password}
              onChange={handleInputChange}
              error={Boolean(errors?.password)}
              helperText={errors?.password}
              autoComplete="new-password"
              className="w-[273px] h-11"
            />
            <button type="button" className="flex absolute right-0 bottom-[17px]" onClick={passwordVisibility}>
              {showPassword ? (
                <VisibilityIcon sx={{ color: "#a1a0a3" }} />
              ) : (
                <VisibilityOffIcon sx={{ color: "#a1a0a3" }} />
              )}
            </button>
          </div>
        </div>
        <div className=" flex w-[570px] flex-col gap-[11px] h-[345px]">
          <p
            className="w-full h-6 font-semibold text-[18px]"
            style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
          >
            Range of Access
          </p>
          <div className="w-[570px] h-auto gap-4 pl-3">
            <ul className=" flex flex-col gap-4 list-disc pl-4">
              <div className="flex flex-col w-auto h-[120px] p-3 gap-3">
                <div className="w-auto h-5 flex justify-between">
                  <li
                    className="w-[96px] h-5 text-black text-[14px] font-medium"
                    style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                  >
                    Dashboard
                  </li>

                </div>
                <hr className="w-[546px] h-1 bg-[#D9D9D9] self-end" />
                <ul className="flex flex-col gap-4 list-disc pl-4">
                  <div className="h-5 flex justify-between">
                    <li
                      className="h-5 text-[#888888] text-[14px] font-medium"
                      style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                    >
                      Limited Access
                    </li>
                    <div>

                      <IOSSwitch
                        checked={isLimitedView}
                        onChange={handleLimitedViewChange}
                      />
                    </div>
                  </div>
                  <div className="h-5 flex justify-between">
                    <li
                      className=" h-5 text-[#888888] text-[14px] font-medium"
                      style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                    >
                      Full Access
                    </li>
                    <div>

                      <IOSSwitch
                        checked={isFullView}
                        onChange={handleFullViewChange}
                      />
                    </div>
                  </div>
                </ul>
              </div>
              <div className="flex flex-col w-auto h-[120px] p-3 gap-3">
                <div className="w-auto h-5 flex justify-between">
                  <li
                    className="w-fit h-5 text-black text-[14px] font-medium"
                    style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                  >
                    User Management
                  </li>
                </div>
                <hr className="w-[546px] h-1 bg-[#D9D9D9] self-end" />
                <ul className="flex flex-col gap-4 list-disc pl-4">

                  <div className="h-5 flex justify-between">
                    <li
                      className=" h-5 text-[#888888] text-[14px] font-medium"
                      style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                    >
                      Limited Access
                    </li>
                    <div>
                      <IOSSwitch
                        checked={isUserView}
                        onChange={handleViewAccessChange}
                      />
                    </div>
                  </div>
                  <div className="h-5 flex justify-between">
                    <li
                      className=" h-5 text-[#888888] text-[14px] font-medium"
                      style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                    >
                      Full Access
                    </li>
                    <div>
                      <IOSSwitch
                        checked={isUserFull}
                        onChange={handleFullAccessChange}
                      />
                    </div>
                  </div>
                </ul>
              </div>
              <div className="flex flex-col w-auto h-[120px] p-3 gap-3">
                <div className="w-auto h-5 flex justify-between">
                  <li
                    className="w-fit h-5 text-black text-[14px] font-medium"
                    style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                  >
                    Review Management
                  </li>
                  <div>

                  </div>
                </div>
                <hr className="w-[546px] h-1 bg-[#D9D9D9] self-end" />
                <ul className="flex flex-col gap-4 list-disc pl-4">
                  <div className="h-5 flex justify-between">
                    <li
                      className=" h-5 text-[#888888] text-[14px] font-medium"
                      style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                    >
                      Limited Access
                    </li>
                    <div>
                      <IOSSwitch
                        checked={isLimitRev}
                        onChange={handleLimitRevChange}
                      />
                    </div>
                  </div>
                  <div className="h-5 flex justify-between">
                    <li
                      className=" h-5 text-[#888888] text-[14px] font-medium"
                      style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                    >
                      Full Access
                    </li>
                    <div>
                      <IOSSwitch
                        checked={isFullRev}
                        onChange={handleFullRevChange}
                      />
                    </div>
                  </div>
                </ul>
              </div>

              <div className="w-auto h-5 flex justify-between p-3">
                <li
                  className="w-fit h-5 text-black text-[14px] font-medium"
                  style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                >
                  Manage Admins / Sub-Admins
                </li>
                <div>
                  <IOSSwitch
                    checked={isSubAdmin}
                    onChange={handleSubAdmin}
                  />
                </div>
              </div>
              <div className="w-auto h-5 flex justify-between p-3">
                <li
                  className="w-fit h-5 text-black text-[14px] font-medium"
                  style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
                >
                  Finance Management
                </li>
                <div>
                  <IOSSwitch
                    checked={isFinance}
                    onChange={handleFinance}
                  />
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div className="w-[232px] h-[45px] flex gap-[12px] mt-[35px] m-auto">
        <button
          className="w-[110px] rounded-[6px] py-[10px] px-4 bg-[#EEEEEE] text-[#888888] text-4 font-bold"
          style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="w-[110px] rounded-[6px] py-[10px] px-4 bg-[#524CBB] text-white text-4 font-bold"
          style={{ fontFamily: "Plus Jakarta Sans,sans-serif" }}
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};
ManageAdd.propTypes = {
  setIsAdded: PropTypes.bool,
  setIsOpenModal: PropTypes.func,
};

export default ManageAdd;
