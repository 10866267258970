import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const SideNavItem = ({ to, label, img, children }) => {
    const location = useLocation();
    const isActive = location.pathname === to;
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (

        <div>
            <Link to={to}>
                <ListItem
                    className={`flex gap-[4px] flex-row justify-center items-center listItem ${isActive ? 'activeHover' : ''} ${children ? '!pr-0' : ""}`}

                >
                    {img && <img
                        src={img}
                        alt="icon"
                        className="w-[22px] h-[22px]"
                    />}
                    <ListItemText
                        sx={{
                            fontFamily: "Plus Jakarta Sans",
                            lineHeight: "20px",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}
                        primary={label}
                    />
                    {children && (
                        isExpanded ? <KeyboardArrowUpIcon onClick={handleToggleExpand} /> : <KeyboardArrowDownIcon onClick={handleToggleExpand} />
                    )}


                </ListItem>

            </Link>
            {isExpanded && children}
        </div>
    );
};

SideNavItem.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string,
    img: PropTypes.string,
    children: PropTypes.node,
};

export default SideNavItem;
