import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import { Paper } from '@mui/material';
import cross_icon from '../Assets/images/icons/cross_icon.png';

const GenericModal = ({ open, handleClose, title, content, isCross, width, height }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper className='font-semibold h-auto p-6 flex flex-col gap-5' sx={{
                width: width,
                height: height,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
            }}>
                <div className='flex flex-col align-center  h-full' >
                    {isCross &&
                        <div className={`flex flex-row justify-between gap-4 ${title && "items-center"}`}>
                            <p className="text-5 m-auto" style={{ fontSize: "20px", fontWeight: "700", fontFamily: "inherit" }}>{title}</p>
                            {!title && content}
                            <button type="button" onClick={handleClose} className="cursor-pointer">
                                <img src={cross_icon} alt="" className="w-[14px] h-[14px]" />
                            </button>
                        </div>
                    }
                    {title && content}
                    {!isCross && content}
                </div>
            </Paper>
        </Modal>
    );
};

GenericModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    content: PropTypes.node,
    isCross: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default GenericModal;
