import React, { useState, useEffect } from 'react'
import Download from "../../../../Assets/images/icons/download.png";
import { motion } from "framer-motion";
import Eye from '../../../../Assets/images/icons/eye.png';
import { financeData, searchByTxn } from '../../../API/financeApi';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import GenericModal from '../../../GenericModal';
import ViewTransaction from './viewTransaction';
import { MenuItem, Pagination, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { getDateRange } from '../../CustomDatePicker/DateRangeFunction';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker';
import SearchIcon from "../../../../Assets/images/icons/search.png";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Moment from 'react-moment';
import API from '../../../API/urlConstants';
 
const tableHeading = [
  "S. No.",
  "Transaction ID",
  "Date and Time",
  "Remittance",
  "User Type",
  "Amount",
  "Payment Method",
  "Status",
  "Action",
];
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  zIndex: "1",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "30px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {

    },
  },
}));
const limitOptions = [10, 25, 50, 75, 100]

const FinanceManagement = () => {
  const [selectedButton, setSelectedButton] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchFilled, setIsSearchFilled] = useState(false);
  const [customRange, setCustomRange] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const currentDate = new Date();
  const [typeFilter, setTypeFilter] = useState("ALL");
  const [customDateRange, setCustomDateRange] = useState({
    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0).toISOString(),
    endDate: new Date().toISOString(),
  })

  const handlePageChange = (event, page) => {
    setPage(page);
  }
  const handleButtonClick = (buttonIndex) => {
    const currentDate = new Date();
    setSelectedButton(buttonIndex);

    switch (buttonIndex) {
      case 1:
        setCustomRange(false);

        setCustomDateRange((date) => ({
          ...date,
          startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0).toISOString(),
          endDate: new Date().toISOString(),
        }))
        setPage(1);
        break;
      case 2:
        setCustomRange(false);
        setCustomDateRange(getDateRange("lastWeek"));
        setPage(1);
        break;
      case 3:
        setCustomRange(false);
        setCustomDateRange(getDateRange("lastMonth"));
        setPage(1);
        break;
      case 4:
        setCustomRange(false);
        setCustomDateRange(getDateRange("lastYear"));
        setPage(1);
        break;
      case 5:
        setCustomRange(true);
        setPage(1);
        break;
      default:
        setCustomRange(false);
        setCustomDateRange((prevDate) => ({
          ...prevDate,
          startDate: "",
          endDate: "",
        }));
        setPage(1);
        break;
    }
  };

  const handleDateRange = (dateObj) => {
    setCustomDateRange(dateObj);
    setPage(1);
  };
  ;

  const handleCloseDatePicker = () => {
    setCustomRange(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
    setView(!view)
  };
  const handleViewClick = (row) => {
    setData(row)
    handleOpenModal();
    setView(!view);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setView(!view)
  };
  const handleSearchClear = (event) => {
    event.target.value ? setIsSearchFilled(true) : setIsSearchFilled(false);
    setSearchQuery("");
    handleSearch("");
  };
  const handleChange = (event) => {
    event.target.value ? setIsSearchFilled(true) : setIsSearchFilled(false);
    setSearchQuery(event?.target?.value);
    handleSearch(event?.target?.value);
  };
  const getPreferenceLabel = (preference) => {
    switch (preference) {
      case "GIVETHANKS":
        return <p>Give Thanks</p>;
      case "GETTHANKS":
        return <p>Get Thanks</p>;
      default:
        return <p
          className="w-max text-[14px] font-[600]"
          style={{ fontFamily: "Plus Jakarta Sans", color: "rgba(136, 136, 136, 0.5)" }}
        >
          N/A
        </p>;
    }
  };
  const handleDownloadCSV = async () => {
    try {
      const apiUrl =
        `${API.DOWNLOAD_CSV}?csvType=financeManagement&startDate=${customDateRange?.startDate}&endDate=${customDateRange?.endDate}`;
      const response = await fetch(apiUrl);
      if (!response.ok) {
        toast.error("Failed to fetch CSV data", {
          autoClose: 5000,
          toastId: "Failed to fetch CSV data"
        });
      }
      const csvData = await response.text();
      const blob = new Blob([csvData], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "finance.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error("Error downloading CSV", {
        autoClose: 5000,
        toastId: "Error Downloading CSV"
      });
    }
  };
  const handlePaginationLimitChange = (event) => {
    setLimit(event?.target?.value);
    setPage(1);
  };
  const handleTypeChange = (e) => {

    setTypeFilter(e?.target?.value);
    setPage(1);
  }
  const handleSearch = async (value) => {
    if (value !== "") {
      setLoading(true);
      searchByTxn(value)
        .then((response) => { setTableData(response?.data?.data); setTotalCount(0) })
        .catch((error) => toast.error("Error fetching search results:", {
          autoClose: 5000, toastId: "Error fetching search results"
        }))
        .finally(() => setLoading(false));
    }
    else if (value === "") {
      fetchTransactionDetails();
    }

  };
  const paymentMethodType = (data) => {
    return data
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  const fetchTransactionDetails = () => {
    const filterType = typeFilter === "ALL" ? "" : typeFilter;
    setLoading(true);
    financeData(customDateRange?.startDate, customDateRange?.endDate, limit, (page), filterType)
      .then((response) => {
        setSearchQuery("");
        setTableData(response?.data?.data?.response);
        setTotalCount(response?.data?.data?.count);
      })
      .catch((error) => {
        setTableData([]);
        toast.error(error?.messsage, {
          autoClose: 5000,
          toastId: "Error fetching data",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    fetchTransactionDetails();
  }, [customDateRange, limit, page, typeFilter])
  return (
    <Paper
      className=" h-[100%] max-h-[87vh] pb-6 p-6 flex flex-col gap-6"
      style={{ borderRadius: "12px" }}
      component={Paper}>
      <div
        className="flex justify-between flex-wrap"
        style={{
          alignItems: "center", alignContent: "center"
        }}
      >
        <p
          className="text-[18px] font-[700] text-black"
          style={{ fontFamily: "Plus Jakarta Sans" }}
        >
          Finance Management
        </p>

        <div
          className="flex gap-3 h-11 font-[600] text-[14px] "
          style={{ fontFamily: "Plus Jakarta Sans" }}
        >
          <button
            type="button"
            className="flex flex-row justify-between items-center w-[174px] gap-1 rounded-[6px] py-[10px] px-4 bg-[#524CBB]"
            onClick={handleDownloadCSV}
          >
            <p
              className="font-bold text-white"
              style={{
                fontFamily: "Plus Jakarta Sans,sans-serif",
                lineHeight: "24px",
                fontWeight: "500",
                textSize: "16px",
                padding: "0",
              }}
            >
              Download CSV
            </p>
            <img
              src={Download}
              alt=""
              className="flex justify-center items-center m-auto w-[18.32px] h-[18.32px]  "
            />
          </button>
          <Select
            labelId="pagination-limit-select-label"
            id="pagination-limit-select"
            value={typeFilter || 0}
            displayEmpty
            onChange={handleTypeChange}
            className="flex justify-between items-center min-w-[110px] rounded-[6px] gap-1 py-2 px-[14px] bg-[#EEEEEE]"
            style={{
              fontFamily: "Inter",
              lineHeight: "20px",
              fontWeight: "500",
              textSize: "14px",
              padding: "0",
              color: "#888888",
            }}
          >
            <MenuItem
              style={{
                "&:hover": {
                  backgroundColor: "#EEEEEE",
                },
              }}
              value={"ALL"}
            >
              All Type
            </MenuItem>
            <MenuItem
              style={{
                "&:hover": {
                  backgroundColor: "#EEEEEE",
                },
              }}
              value={"GETTHANKS"}
            >
              Get Thanks
            </MenuItem>
            <MenuItem
              style={{
                "&:hover": {
                  backgroundColor: "#EEEEEE",
                },
              }}

              value={"GIVETHANKS"}
            >
              Give Thanks
            </MenuItem>
          </Select>
          <Search className="flex flex-row justify-between relative !mr-0">
            <SearchIconWrapper>
              <img src={SearchIcon} alt="" className="w-[22px] h-[22px]" />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search By Transaction Id"
              value={searchQuery}
              onChange={handleChange}
              inputProps={{ "aria-label": "search" }}
              className="flex w-[272px] rounded-[6px] gap-1 border-[1px] p-[10px] bg-[#EEEEEE]"
            />
            {isSearchFilled && (
              <button
                className="bg-[#EEEEEE] outline-none px-2 rounded-r-[6px] absolute right-0 top-[12px]"
                onClick={handleSearchClear}
              >
                X
              </button>
            )}
          </Search>

        </div>
      </div>
      < div className="switcher flex justify-evenly bg-[#D9D9D9] rounded-[6px] w-[757px] h-11" >
        <button type='button' className={`bg-red-500 ${selectedButton === 1 ? "bg-pink-200" : ""}  w-[151.4px] h-11 rounded-tl-[6px] rounded-bl-[6px] py-[10px] px-4`}
          style={{
            backgroundColor: selectedButton === 1 ? "#524CBB" : "#F8F8F8",
            color: selectedButton === 1 ? "white" : "black"
          }} onClick={() => handleButtonClick(1)}>Daily Report</button>
        <button type='button' style={{
          backgroundColor: selectedButton === 2 ? "#524CBB" : "#F8F8F8",
          color: selectedButton === 2 ? "white" : "black"
        }} onClick={() => handleButtonClick(2)} className=' w-[151.4px] h-11 border-l-[1px]  px-4'>Weekly Report</button>
        <button type='button' style={{
          backgroundColor: selectedButton === 3 ? "#524CBB" : "#F8F8F8",
          color: selectedButton === 3 ? "white" : "black"
        }} onClick={() => handleButtonClick(3)} className='w-[151.4px] h-11 border-l-[1px] border-r-[1px] py-[10px] px-4'>Monthly Report</button>
        <button type='button' style={{
          backgroundColor: selectedButton === 4 ? "#524CBB" : "#F8F8F8",
          color: selectedButton === 4 ? "white" : "black"
        }} onClick={() => handleButtonClick(4)} className='w-[151.4px] h-11 border-r-[1px] py-[10px] px-4'>Yearly Report</button>
        <button type='button' style={{
          backgroundColor: selectedButton === 5 ? "#524CBB" : "#F8F8F8",
          color: selectedButton === 5 ? "white" : "black"
        }} onClick={() => handleButtonClick(5)} className='w-[151.4px] h-11 rounded-tr-[6px] rounded-br-[6px] py-[10px] px-4'>Custom Range</button>
      </div >
      {customRange && <CustomDatePicker setDateRange={customDateRange} getDateRange={(e) => handleDateRange(e)} onClose={handleCloseDatePicker} right={"495px"} top={"137px"} />}
      {loading && (
        <div className="flex items-center justify-center mt-20">
          <CircularProgress style={{ color: "#888888", opacity: "0.5" }} />
        </div>

      )}
      {!loading && <TableContainer className="card overflow-auto" >
        {tableData?.length !== 0 ? <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
          transition={{ duration: 0.8 }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className="w-[194px] h-11 p-3">

                {tableHeading.map((heading) => (
                  <TableCell key={heading} className="w-max h-11 p-3">
                    <p
                      className="w-max text-[14px] font-[600] text-[#888888]"
                      style={{ fontFamily: "Plus Jakarta Sans" }}
                    >
                      {heading}
                    </p>
                  </TableCell>
                ))}

              </TableRow>
            </TableHead>


            <TableBody>
              {tableData?.map((row, index) => (
                <TableRow
                  key={row?._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  className="w-[fit] h-13"
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: "#888888",
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    <p>{index + 1}</p>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px", fontWeight: "400" }}
                  >
                    {row?.transactionId ? (<p >{row?.transactionId}</p>) : (<p className="text-[14px] font-[600]"
                      style={{
                        color: "rgba(136, 136, 136, 0.5)",
                        fontFamily: "Plus Jakarta Sans",
                      }}>N/A</p>)}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    <Moment format="DD MMM YYYY ,  hh:mm A" >
                      {row?.createdAt}
                    </Moment>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px", fontWeight: "400" }}
                  >
                    {row?.payeeDetails?.userName ? (<p >{row?.payeeDetails?.userName}</p>) : (<p className="text-[14px] font-[600]"
                      style={{
                        color: "rgba(136, 136, 136, 0.5)",
                        fontFamily: "Plus Jakarta Sans",
                        fontStyle: "italic"
                      }}>Deleted User</p>)}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px", fontWeight: "400" }}
                  >
                    {row?.payeeDetails?.preference ? getPreferenceLabel(row?.payeeDetails?.preference) : (<p className="text-[14px] font-[600]"
                      style={{
                        color: "rgba(136, 136, 136, 0.5)",
                        fontFamily: "Plus Jakarta Sans",
                      }}>N/A</p>)}
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px", fontWeight: "400" }}
                  >
                    {row?.amount ? <p >$ {row?.amount}</p> : (<p className="text-[14px] font-[600]"
                      style={{
                        color: "rgba(136, 136, 136, 0.5)",
                        fontFamily: "Plus Jakarta Sans",
                      }}>N/A</p>)}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px", fontWeight: "400" }}
                  >
                    {row?.paymentMethod ? (paymentMethodType(row?.paymentMethod)) : (<p className="text-[14px] font-[600]"
                      style={{
                        color: "rgba(136, 136, 136, 0.5)",
                        fontFamily: "Plus Jakarta Sans",
                      }}>N/A</p>)}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px", fontWeight: "500" }}
                  >
                    {row?.paymentStatus ? <p>{row?.paymentStatus.charAt(0).toUpperCase() + row?.paymentStatus.slice(1)}</p> : (<p className="text-[14px] font-[600]"
                      style={{
                        color: "rgba(136, 136, 136, 0.5)",
                        fontFamily: "Plus Jakarta Sans",
                      }}>N/A</p>)}
                  </TableCell>

                  <TableCell align="left" >

                    <button
                      type="button"
                      className="w-[36.8px] h-[36.8px] bg-[#EEEEEE] rounded-[5.02px] p-[10.04px] cursor-pointer"
                      onClick={(e) => handleViewClick(row)}
                    >
                      <motion.img src={Eye} alt="" className="w-5 h-5" />
                    </button>


                    {row.action}
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </motion.div> : <p className="flex justify-center mt-[150px] text-[18px] font-[600] text-[#888888]">No data found</p>}
      </TableContainer >}
      {view && <GenericModal open={isModalOpen} title={"Transaction Details"} handleClose={handleCloseModal} content={<ViewTransaction viewData={data} />} isCross={true} width={"631px"} height={"574px"} />}
      <div className="flex justify-between mt-auto">
        <Select
          labelId="pagination-limit-select-label"
          value={limit}
          onChange={handlePaginationLimitChange}
          className="w-[66px] h-11 bg-[#EEEEEE] "
          style={{
            color: "#888888",
            fontSize: "14px",
            fontWeight: "500",
            width: "fit-content"
          }}
        >
          {limitOptions.map((options) => (<MenuItem
            key={options}
            style={{
              "&:hover": {
                backgroundColor: "#EEEEEE",
              },
            }}
            value={options}
          >
            {options}
          </MenuItem>))}
        </Select>

        <Stack spacing={2} >
          <Pagination
            count={Math.ceil(totalCount / limit)}
            defaultPage={1}
            siblingCount={3}
            shape="rounded"
            page={page}
            onChange={handlePageChange}
          />
        </Stack>
      </div>
    </Paper>
  )
}

export default FinanceManagement