import { wrappedFetch } from "./urlFunctions";
import API from "./urlConstants";


export const listRevData = (limit = 10, filter = "", pageNo = 1, startDate = "", endDate = "") => {
    return wrappedFetch(`${API.REV_DATA}?&limit=${limit}&filter=${filter}&pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}`, "GET");
}

export const reviewActions = (data) => {
    return wrappedFetch(API.REVIEW_ACTIONS, "PUT", data);
}
export const cancelPremium = (data) => {
    return wrappedFetch(API.ADMIN_REMOVE_PREMIUM, "POST", data);
}