import { wrappedFetch } from "./urlFunctions";
import API from "./urlConstants";

export const financeData = (startDate, endDate, limit, pageNo, userType) => {
    return wrappedFetch(`${API.FINANCE_DATA}?startDate=${startDate}&endDate=${endDate}&limit=${limit}&pageNo=${pageNo}&userType=${userType}`, "GET");
}

export const searchByTxn = (id) => {
    return wrappedFetch(`${API.SEARCH_FINANCE}?_idTxn=${id}`, "GET");
}

export const subscriptionData = (data) => {
    return wrappedFetch(`${API.SUBSCRIPTION_LIST}`, "POST", data);
}